.card {
  border: 0;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
  border-radius: $border-radius-small;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

  .card-header {
    padding: 0;
    border-bottom: 0;
    border-radius: 0;
    background-color: transparent;
    background-color: $transparent-bg;
  }

    .card-body {
        img {
          opacity: 0.95;
          max-width: 320px;
          margin-bottom: 20px;
          transition: all 0.4s;
          box-sizing: content-box;

          &.selected {
            opacity: 1;
            padding: 10px;
            max-width: 305px;
            border: 2px solid #cda567;
            box-shadow: 0 0 15px #cda567;
          }
        }
    }

  .card-footer {
    padding: 0;
    margin-top: 15px;
    background-color: $transparent-bg;
    border:0;
  }

  &[data-background-color="orange"]{
      background-color: $primary-color;
  }

  &[data-background-color="red"]{
      background-color: $danger-color;
  }

  &[data-background-color="yellow"]{
      background-color: $warning-color;
  }

  &[data-background-color="blue"]{
      background-color: $info-color;
  }

  &[data-background-color="green"]{
      background-color: darken($success-color, 5%);
  }
}