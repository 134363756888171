button,
input,
optgroup,
select,
textarea{
    font-family: $sans-serif-family;
}
h1,h2,h3,h4,h5,h6{
    font-weight: $font-weight-normal;
}

small{
    font-size: 60%;
}

a {
    color: $primary-color;
    &:hover,
    &:focus{
        color: $primary-color;
    }
}
h1, .h1 {
    font-size: $font-size-h1;
    line-height: 1.15;
    margin-bottom: $margin-base-vertical * 2;

    small{
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        opacity: .8;
    }
}
h2, .h2{
    font-size: $font-size-h2;
    margin-bottom: $margin-base-vertical * 2;
}
h3, .h3{
    font-size: $font-size-h3;
    margin-bottom: $margin-base-vertical * 2;
    line-height: 1.4em;
}
h4, .h4{
    font-size: $font-size-h4;
    line-height: 1.45em;
    margin-top: $margin-base-vertical * 2;
    margin-bottom: $margin-base-vertical;

    & + .category,
    &.title + .category{
        margin-top: -5px;
    }
}
h5, .h5 {
    font-size: $font-size-h5;
    line-height: 1.4em;
    margin-bottom: 15px;

    &.category{
        font-weight: 400;
    }
}
h6, .h6{
    font-size: $font-size-h6;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}
p{
    line-height: 1.61em;
    font-weight: 300;
    font-size: 1.2em;
}

.title{
    font-size: 38px;
    padding-top: 10px;
    padding-bottom: 20px;
    font-family: 'Suez One', sans-serif;


    &.title-up {
        text-transform: uppercase;

        a {
            color: $black-color;
            text-decoration: none;
        }
    }
    & + .category{
        margin-top: -25px;
    }
}

.react-select {
    min-width: 330px;
    font-size: 20px;

    input {
        font-family: 'Bellefair', sans-serif;
    }
}

.description,
.card-description,
.footer-big p{
    color: $dark-gray;
    font-weight: $font-weight-light;
}
.category{
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    color: $dark-gray;
}
.text-primary {
  color: $brand-primary !important;
}
.text-info {
  font-family: 'Suez One', sans-serif;
  color: $brand-info !important;
  font-weight: 400;
}
.text-success {
  color: $brand-success !important;
}
.text-warning {
  color: $brand-warning !important;
}
.text-danger {
  color: $brand-danger !important;
}
.text-black{
  color: $light-black;
}

.blockquote{
    border-left: none;
    border: 1px solid $default-color;
    padding: 20px;
    font-size: $font-size-blockquote;
    line-height: 1.8;

    small{
        color: $default-color;
        font-size: $font-size-small;
        text-transform: uppercase;
    }

    &.blockquote-primary{
        border-color: $primary-color;
        color: $primary-color;

        small{
            color: $primary-color;
        }
    }

    &.blockquote-danger{
        border-color: $danger-color;
        color: $danger-color;

        small{
            color: $danger-color;
        }
    }

    &.blockquote-white{
        border-color: $opacity-8;
        color: $white-color;

        small{
            color: $opacity-8;
        }
    }
}

#whatsapp_chat_widget {
    .wa-chat-box {

        .wa-chat-box-header {
            flex-direction: row-reverse;
            justify-content: center;

            .wa-chat-box-brand {
                width: 70px !important;
                height: 70px !important;
                border-radius: 100% !important;

                .wa-chat-box-brand-text {
                    text-align: right !important;
                }
            }

            .wa-chat-box-brand-subtitle {
                text-align: right !important;
            }

            .wa-chat-bubble-close-btn {
                left: 15px;
                top: 15px !important;
                right: auto !important;
            }
        }

        .wa-chat-box-content {
            display: flex !important;
            
            .wa-chat-box-content-chat {
                text-align: right !important;
            }
        }

        .wa-chat-box-send { 
            a {
                background-color: #9c1d3b !important;
                transition: opacity 0.4s;

                &:hover {
                    opacity: 0.92;
                }
            }

            .wa-chat-box-poweredby {
                display: none;
            }
        }
    }
}

#paypal-button-container {
    max-width: 460px;
    margin: 40px auto;
    border-radius: 4px;
    background: #f5f5f5;
    padding: 65px 35px 45px;
}

.social-share {
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    margin: auto;
    display: flex;
    height: 385px;
    padding: 5px 0;
    position: fixed;
    background: #e2e2e2;
    flex-direction: column;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 10px #bd687c;
}

@media screen and (max-width: 600px){
    .social-share {
        top: auto;
        bottom: 0;
        padding: 0;
        width: 100%;
        height: 40px;
        overflow-x: scroll;
        border-radius: 4px;
        flex-direction: row;
        justify-content: space-around;
    }
}