body{
    color: $black-color;
    font-size: $font-size-base;
    font-family: $sans-serif-family;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Bellefair', serif;
}

.main {
    position: relative;
    background: $white-color;

    .invitation-section {
        background-size: cover;
        background-repeat: no-repeat;

        .invitation-step {
            max-width: 320px;
            margin: 0 auto 60px;

            a {
                font-size: 22px;
                font-weight: 700;
                align-items: center;
                display: inline-flex;
                justify-content: center;
            }

            .category {
                margin-top: 5px;
                margin-bottom: 10px;
            }

            h3 {
                margin-bottom: 0;
            }

            .description {
                margin-top: 5px;
                color: #525252;
                margin-bottom: 10px;
            }
        }
    }
}
/* Animations */
.nav-pills .nav-link,
.nav-item .nav-link,
.navbar, .nav-tabs .nav-link,
.tag, .tag [data-role="remove"]{
    @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.card a,
.bootstrap-switch-label:before{
    @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle:after,
[data-toggle="collapse"][data-parent="#accordion"] i{
  @include transition-on-prop(transform, $fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
[data-toggle="collapse"][data-parent="#accordion"][aria-expanded="true"] i{
    @include rotate-180();
}

.button-bar{
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white-bg;

    & + .button-bar{
        margin-top: 7px;
    }

    &:nth-child(2){
        width: 17px;
    }
}

.separator-line{
    height: 2px;
    width: 44px;
    background-color: $default-color;
    margin: 20px auto;

    &.separator-primary{
        background-color: $primary-color;
    }
}

.section-space{
    height: 62px;
    display: block;
}

.pull-left{
  float: left;
}
.pull-right{
  float: right;
}

.title-up{
  text-transform: uppercase;
}
