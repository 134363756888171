.card-plain{

    .card-body, .card-header {
        padding-left: 5px;
        padding-right: 5px;
    }

    .card-header:after {
        width: 100%;
        left: 0;
    }
}
