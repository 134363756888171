.footer {
    padding: 24px 0;

    &.footer-default{
        display: flex;
        font-size: 20px;
        justify-content: space-between;
    }

    nav {
    }

    ul {
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            a {
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
    }
}
